.flex-grow-2 {
  flex-grow: 2 !important
}

.bring-to-front {
  z-index: 999;
}

.hover-primary:hover {
  color: $primary;
}
